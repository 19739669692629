class {
  onCreate(input, out) {
    this.state = {
      errorModel: input.errorModel
    }
  }
}
<div class="container">
  <i18n-use localizedErrorText="authnauth/error"/>
  <div id="mainContent" role="main">
    <div class="grid ">
      <div class="grid__group grid__group--full" style="padding-left:0px">
        <div class="headingDiv">
          <h1 id="welcomepg">
            <dangerouslyRenderInnerHTML value=localizedErrorText.get("authnauthError", state.errorModel, ) />
          </h1>
        </div>
      </div>
      <div
        class="contentDivErrorrectangle"
        style="border-radius: 8px; height:auto;"
      >
        <div class="grid__group grid__group--full  ">
          <b class="errIconCls">
            <p id="errfcsid" class="g-hdn"></p>
          </b>
          <p class="contentDivErrordesc" id="content1">
            <b>
              <dangerouslyRenderInnerHTML value=localizedErrorText.get("authnauthContent1", state.errorModel, ) />
            </b>
            <br>
            <dangerouslyRenderInnerHTML value=localizedErrorText.get("authnauthContent2", state.errorModel, ) />
          </p>
        </div>

        <div class="grid__group grid__group--full" style="padding-left: 0px;">
          <br>
          <p class="contentDivErrorPts" id="content4">
            <dangerouslyRenderInnerHTML value=localizedErrorText.get( "authnauthContent3", state.errorModel, ) />
            <ul class="contentDivError">
              <li class="contentDivErrorli" style="padding: 0.1em 1em;">
                <dangerouslyRenderInnerHTML value=localizedErrorText.get( "authnauthContent4", state.errorModel, ) />
              </li>
              <if(state.errorModel.declineUrl == "")>
                <li class="contentDivErrorli" style="padding: 0.1em 1em;">
                  <dangerouslyRenderInnerHTML value=localizedErrorText.get( "authnauthContent6", state.errorModel, ) />
                </li>
              </if>
              <if(state.errorModel.declineUrl != "")>
                <li class="contentDivErrorli" style="padding: 0.1em 1em;">
                  <dangerouslyRenderInnerHTML value=localizedErrorText.get( "authnauthContent5", state.errorModel, ) />
                </li>
              </if>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
style {  }
